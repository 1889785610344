import React from 'react'
import Layout from '../Components/Layout/Layout'

const Prices = () => {
  return (
    <Layout>
        <h1 className='text-center pt-100'>Prices</h1>
    </Layout>
  )
}

export default Prices