import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CstatsChat from './Pages/CstatsChat';
import Welcome from './Pages/Welcome';
import Contact from './Pages/Contact';
import Prices from './Pages/Prices';
import Cookies from './Pages/Cookies';
import { ThemeProvider } from './Components/ThemeContext';
import Api from './Pages/Api';

function App() {
  return (
    <ThemeProvider>
      <Routes>
        <Route path='/chat' element={<CstatsChat />} />
        <Route path='/' element={<Welcome />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/prices' element={<Prices />} />
        <Route path='/policies' element={<Cookies />} />
        <Route path='/api' element={<Api />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
