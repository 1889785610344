import React, { useState } from 'react'
import siteLogo from '../../Assets/Images/sit-logo.png'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {

    const [headerVisible, setHeaderVisible] = useState(false)


    const toggleMobileMenu = () => {
        setHeaderVisible(!headerVisible);
    };


    return (
        <>
            <header className="desktop-header">
                <div className="custom-header-width">
                    <div className="brand-img-parent">
                        <div className="brand-img">
                            <Link to="/"><img src={siteLogo} alt /></Link>
                        </div>
                    </div>
                    <div className="custom-nav-list">
                        <ul>
                            <li>
                                <NavLink to="/api">API</NavLink>
                            </li>
                            <li>
                                <NavLink to="/prices">Pricing</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact-us">Contact</NavLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </header>

            <header className="mobile-header">
                <div className="mobile-header-parent">
                    <div className="mobile-header-img">
                        <Link to="/"><img src={siteLogo} alt /></Link>
                    </div>
                    <div className="custom-toggle-icon" onClick={toggleMobileMenu}>
                        <span className="fa fa-bars" />
                    </div>
                </div>
            </header>


            {
                headerVisible ? (
                    <>
                        <div className="mobile-header-list">
                            <ul>
                                <li><NavLink to="/api" className="custom-nav-link toggle-dropdown">API</NavLink></li>
                                <li><NavLink to="/prices" className="custom-nav-link toggle-dropdown">Pricing</NavLink></li>
                                <li><NavLink to="/contact-us" className="custom-nav-link toggle-dropdown">Contact</NavLink></li>
                            </ul>
                        </div>
                    </>
                ) : ""
            }
        </>
    )
}

export default Header