import React, { useState } from 'react'
import siteLogo from '../Assets/Images/sit-logo.png'
import tennisLogo from '../Assets/Images/tennis.png'
import basketbalLogo from '../Assets/Images/basketball.png'
import footballLogo from '../Assets/Images/football.png'
import rugbyLogo from '../Assets/Images/rugby.png'
import baseballLogo from '../Assets/Images/baseball.svg'
import hockyLogo from '../Assets/Images/hocky.svg'
import golf from '../Assets/Images/golf.png'
import { Link } from 'react-router-dom'
import Layout from '../Components/Layout/Layout'

const Welcome = () => {

  return (
    <>
      <Layout>
        <div className="main-container">

          <div className="welcome-banner">

            <div className="banner-content">
              <h3>Your Sports Assistant.</h3>
              <ul>
                <li className="banner-list-item">Questions</li>
                <li className="banner-list-item">Statistics</li>
                <li className="banner-list-item">Betting</li>
              </ul>
              <p className="pb-lg-3">We provide you with an AI assistant who can help on all topics related to sport</p>
              <div className="text-center mt-4">
                <Link to="/chat" className="banner-btn">Let's Go!</Link>
              </div>
              <div className="sports-list">
                <div className="sports-card sports-card-1">
                  <div className="card-icon">
                    <img src={tennisLogo} alt />
                  </div>
                  <h4>Tennis</h4>
                </div>
                <div className="sports-card sports-card-2">
                  <div className="card-icon">
                    <img src={footballLogo} alt />
                  </div>
                  <div>
                    <h4>Football</h4>
                    <span>(Soon)</span>
                  </div>
                </div>
                <div className="sports-card sports-card-3">
                  <div className="card-icon">
                    <img src={basketbalLogo} alt />
                  </div>
                  <div>
                    <h4>Basketball</h4>
                    <span>(Soon)</span>
                  </div>
                </div>

                <div className="sports-card sports-card-3">
                  <div className="card-icon">
                    <img src={hockyLogo} alt />
                  </div>
                  <div>
                    <h4>Hocky</h4>
                    <span>(Soon)</span>
                  </div>
                </div>

                <div className="sports-card sports-card-3">
                  <div className="card-icon">
                    <img src={golf} alt />
                  </div>
                  <div>
                    <h4>Golf</h4>
                    <span>(Soon)</span>
                  </div>
                </div>

                <div className="sports-card sports-card-3">
                  <div className="card-icon">
                    <img src={rugbyLogo} alt />
                  </div>
                  <div>
                    <h4>Rugby</h4>
                    <span>(Soon)</span>
                  </div>
                </div>

                <div className="sports-card sports-card-3">
                  <div className="card-icon">
                    <img src={baseballLogo} alt />
                  </div>
                  <div>
                    <h4>BaseBall</h4>
                    <span>(Soon)</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

      </Layout>

    </>
  )
}

export default Welcome