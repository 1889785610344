// CstatsChat.js
import React, { useState } from 'react';
import { useChatHistory } from '../Components/ChatHistoryContext';
import companyLogo from '../Assets/Images/sit-logo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Components/Loader';
import siteLogo from '../Assets/Images/sit-logo.png'
import userLogo from '../Assets/Images/user.png'
import chatboatLogo from '../Assets/Images/chatboat.png'
import { useTheme } from '../Components/ThemeContext';

const CstatsChat = () => {
  const { chatHistory, addChatToHistory, deleteChat } = useChatHistory();
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { isDarkMode, setIsDarkMode } = useTheme();
  const textareaRef = React.useRef(null);
  const chatLogRef = React.useRef(null);

  const scrollToBottom = () => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setQuestion(''); // Clear input immediately
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (!question.trim()) return;

    resetTextareaHeight();
    const messageText = question; // Store the question before clearing
    setQuestion(''); // Clear input immediately

    const userMessage = { sender: 'user', text: messageText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://cstats.back-dev.6hat.fr/ask',
        { question: messageText },
        {
          headers: {
            Authorization: 'Bearer vkI9jw5eMjug5LiS1rG9lYATmWhE6qVe428ejwwgJrh92jdbiBJp2T7rwK2CItWR',
            'Content-Type': 'application/json',
          },
        }
      );

      const botMessage = response.data && (response.data.answer || response.data.output)
        ? { sender: 'bot', text: response.data.answer || response.data.output }
        : { sender: 'bot', text: "I'm not sure how to answer that." };

      setMessages((prevMessages) => [...prevMessages, botMessage]);

    } catch (err) {
      console.error('API call error:', err);
      setError('Error fetching data');
    }

    setLoading(false);
  };

  const handleNewChat = () => {
    if (messages.length > 0) {
      addChatToHistory(messages);
      setMessages([]);
    }
  };

  const handleChatClick = (index) => {
    setSelectedChatIndex(index);
    setMessages(chatHistory[index]);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleTextareaResize = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const resetTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  return (
    <>
      <div className="chat-container">

        <div className='mobile-nav'>
          <div className='nav-icon' onClick={toggleSidebar}>
            <span className='line-1'></span>
            <span className='line-2'></span>
            <span className='line-3'></span>
          </div>

          <div className='mobile-chat-profile'>
            <img src={siteLogo} alt="" />
          </div>
          
        </div>

        {sidebarVisible ? (
          <>
            <div className="mobile-sidebar">
              <div className="side-menu-btn" onClick={handleNewChat}>
                <span>+</span>
                New Chat
              </div>

              <div className='chat-history'>
                {chatHistory.map((chat, index) => (
                  <div key={index} className='chat-tab'>
                    <span className='fa fa-envelope'></span>
                    <p onClick={() => handleChatClick(index)}>
                      {chat.length > 0 ? `${chat[0].text.slice(0, 20)}...` : "No messages"}
                    </p>
                    <div className='action-icons'>
                      <span
                        className='fa-regular fa-trash-can'
                        onClick={() => deleteChat(index)} // Delete chat on click
                      ></span>
                    </div>
                  </div>
                ))}
              </div>

              <div className='sidebar-menu-btn'>
                <div className='menu-btn-bar' onClick={toggleTheme}>
                  <span className={isDarkMode ? 'fa fa-sun' : 'fa fa-moon'}></span>
                  <p>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</p>
                </div>

                <Link to="" className='menu-btn-bar'>
                  <span className='fa-regular fa-trash-can'></span>
                  <p>Clear conversations</p>
                </Link>

                <Link to="/" className='menu-btn-bar'>
                  <span className='fa fa-arrow-right-from-bracket'></span>
                  <p>Log out</p>
                </Link>
              </div>
            </div>
          </>
        ) : ""}



        <div className="sidebar">
          <div className="side-menu-btn" onClick={handleNewChat}>
            <span>+</span>
            New Chat
          </div>

          <div className='chat-history'>
            {chatHistory.map((chat, index) => (
              <div key={index} className='chat-tab'>
                <span className='fa fa-envelope'></span>
                <p onClick={() => handleChatClick(index)}>
                  {chat.length > 0 ? `${chat[0].text.slice(0, 20)}...` : "No messages"}
                </p>
                <div className='action-icons'>
                  <span
                    className='fa-regular fa-trash-can'
                    onClick={() => deleteChat(index)}
                  ></span>
                </div>
              </div>
            ))}
          </div>

          <div className='sidebar-menu-btn'>
            <div className='menu-btn-bar' onClick={toggleTheme}>
              <span className={isDarkMode ? 'fa fa-sun' : 'fa fa-moon'}></span>
              <p>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</p>
            </div>

            <Link to="" className='menu-btn-bar'>
              <span className='fa-regular fa-trash-can'></span>
              <p>Clear conversations</p>
            </Link>

            <Link to="/" className='menu-btn-bar'>
              <span className='fa fa-arrow-right-from-bracket'></span>
              <p>Log out</p>
            </Link>
          </div>
        </div>

        <section className="chat-box">
          <div className='main-chat-container' ref={chatLogRef}>
            <div className="chat-log">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`chat-messsage ${msg.sender === 'user' ? 'user-message' : 'bot-message'}`}
                >
                  <div className="avatar">
                  <img src={msg.sender === 'user' ? userLogo : chatboatLogo} alt={`${msg.sender} avatar`} />
                  </div>
                  <div className="message">{msg.text}</div>
                </div>
              ))}

              {loading && (
                <div className="chat-messsage bot-message">
                  <div className="avatar">
                    <img src={chatboatLogo} alt="" />
                  </div>
                  <div className="message"><Loader /></div>
                </div>
              )}
            </div>
          </div>

          <div className="chat-input-holder">
            <textarea
              ref={textareaRef}
              rows={1}
              className="chat-input-textarea"
              placeholder="Message cstats.io"
              onChange={(e) => {
                setQuestion(e.target.value);
                handleTextareaResize(e);
              }}
              value={question}
              disabled={loading}
              onKeyDown={handleKeyDown}
            />
            <span
              className='fa-sharp fa-solid fa-paper-plane send-message'
              onClick={handleSendMessage}
            ></span>
          </div>
        </section>
      </div>
    </>
  )
}

export default CstatsChat;
