import React from 'react'
import Layout from '../Components/Layout/Layout'

const Api = () => {
    return (
        <>
            <Layout>
                <h1 className='text-center pt-100'>Api Page</h1>
            </Layout>
        </>
    )
}

export default Api