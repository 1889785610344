import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-contact">
                    <div className="contact-box">
                        <Link to="/contact-us">Contact Us</Link>
                    </div>
                    <div>
                        <Link to="/policies">Cookies Policy</Link>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer