// ChatHistoryContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const ChatHistoryContext = createContext();

// Custom hook for using the ChatHistoryContext
export const useChatHistory = () => useContext(ChatHistoryContext);

// Provider component
export const ChatHistoryProvider = ({ children }) => {
  const [chatHistory, setChatHistory] = useState([]);

  // Load chat history from localStorage on component mount
  useEffect(() => {
    const storedChatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
    setChatHistory(storedChatHistory);
  }, []);

  // Save chat history to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
  }, [chatHistory]);

  // Add a new chat to history
  const addChatToHistory = (newChat) => {
    setChatHistory((prevHistory) => [...prevHistory, newChat]);
  };

  // Delete a chat from history by index
  const deleteChat = (index) => {
    const updatedHistory = chatHistory.filter((_, i) => i !== index);
    setChatHistory(updatedHistory);
  };

  return (
    <ChatHistoryContext.Provider value={{ chatHistory, addChatToHistory, deleteChat }}>
      {children}
    </ChatHistoryContext.Provider>
  );
};
