import React from 'react'

const Loader = () => {
  return (
    <>
        <div className='locader-box'>
        <div class="loader"></div>
        </div>
    </>
  )
}

export default Loader